

.postList {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .postList h1 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
  }
  
  .listItem {
    background-color: #ffffff;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    transition: box-shadow 0.3s ease;
  }
  
  .listItem:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .listItem h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .listItem h2 a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .listItem h2 a:hover {
    color: var(--secondary-color);
  }
  
  .listItem p {
    color: var(--text-color);
    margin-bottom: 1rem;
  }
  
  .postMeta {
    font-size: 0.9rem;
    color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .postMeta span {
    margin-right: 1rem;
  }
  
  .btn {
    display: inline-block;
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 1.5rem;
  }
  
  .btn:hover {
    background-color: var(--secondary-color);
  }