/* Post.module.css */

.post {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .post h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .postMeta {
    font-size: 0.9rem;
    color: var(--secondary-color);
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .postMeta span {
    margin-right: 1rem;
  }
  
  .postContent {
    line-height: 1.6;
    color: var(--text-color);
  }
  
  /* Styles for markdown content */
  .postContent h1,
  .postContent h2,
  .postContent h3,
  .postContent h4,
  .postContent h5,
  .postContent h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: var(--primary-color);
  }
  
  .postContent p {
    margin-bottom: 1em;
  }
  
  .postContent a {
    color: var(--link-color);
    text-decoration: none;
  }
  
  .postContent a:hover {
    text-decoration: underline;
  }
  
  .postContent img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em 0;
  }
  
  .postContent blockquote {
    border-left: 4px solid var(--secondary-color);
    padding-left: 1em;
    margin-left: 0;
    font-style: italic;
    color: var(--secondary-color);
  }
  
  .postContent code {
    background-color: #f1f1f1;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
  }
  
  .postContent pre {
    background-color: #f1f1f1;
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  .postContent pre code {
    background-color: transparent;
    padding: 0;
  }
  
  .postContent ul,
  .postContent ol {
    margin-bottom: 1em;
    padding-left: 2em;
  }
  
  .postContent table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
  }
  
  .postContent th,
  .postContent td {
    border: 1px solid var(--border-color);
    padding: 0.5em;
    text-align: left;
  }
  
  .postContent th {
    background-color: #f1f1f1;
    font-weight: bold;
  }