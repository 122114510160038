

.container {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  padding: var(--spacing-large);
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.btn {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: var(--spacing-small) var(--spacing-medium);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: var(--secondary-color);
}