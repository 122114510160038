/* CreatePost.module.css */




.createPost {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    /* background-color: red; */
    
}
  
  .createPost h1 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }
  
  .createPost form > div {
    margin-bottom: 1.5rem;
  }
  
  .createPost label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .createPost input[type="text"] {
    width: 100%;
    padding: 0.5rem 0;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .tags {
    font-size: 0.9rem;
  }
  
  .editorContainer {

    border-radius: 4px;
    overflow: hidden;
  }
  
  .editorToolbar {
    background-color: #2d2d2d;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .toolbarBtn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toolbarBtn:hover {
    background-color: var(--secondary-color);
  }
  
  .editorContent {
    min-height: 300px;
    transition: all 0.1s ease;
  }

  .editorContent.highlight {
    border: 4px dotted black;

  }
  
  .content {
    width: 90%;
    height: 90%;
    min-height: 300px;
    padding: 5%;
    border: none;
    resize: vertical;
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
    background-color: #cecece;
  }
  
  .preview {
    padding: 1rem;
    min-height: 300px;
    background-color: #efefef;
    overflow-y: auto;
  }
  
  .btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: var(--secondary-color);
  }


  .uploadBtn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .uploadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }